/* Create a keyframe called topCircleAnim */

@keyframes topCircleAnim {
  0%, 100%{
    transform: translateY(0) scale(100%);
  } 45%, 55%{
    transform: translateY(40%)  scale(70%);
  }
}

@keyframes  leftCircleAnim {
  0%, 100%{
    transform: translate(0,0) scale(100%);
  } 42%, 55%{
    transform: translate(33%, -19%) scale(70%);
  }
}

@keyframes  rightCircleAnim {
  0%, 100%{
    transform: translate(0,0) scale(100%);
  } 42%, 55%{
    transform: translate(-33%, -19%)  scale(70%);
  }
}

@keyframes  textAnim {
  0%, 100%{
    opacity: 1;
  } 42%, 65%{
    opacity: 0;
  }
}
