.rhhf-group {
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  width: 100%;
  margin-bottom: 1.25rem;
}

.rhhf-control {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.rhhf-control-hidden {
  display: none;
}

.rhhf-field {
  padding: 0.75rem;
  border: 0.0625rem solid #ccc;
  border-radius: 0.25rem;
  font-size: 0.9375rem;
}

.rhhf-field-invalid {
  border-color: red;
}

.rhhf-radio-group,
.rhhf-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.rhhf-label {
  font-size: 0.875rem;
  margin-bottom: 0.3125rem;
}

.rhhf-control.hidden {
  display: none;
}

.rhhf-button {
  padding: 0.75rem 1.5rem;
  border-radius: 0.25rem;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  margin-top: 1.25rem;
}

.rhhf-button:hover {
  background-color: #0056b3;
}

.rhhf-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.rhhf-error {
  position: absolute;
  bottom: -1.125rem;
  margin: 0;
  color: red;
  font-size: 0.875rem;
}

.rhhf-success-message {
  color: green;
  font-size: 1rem;
}

.rhhf-error-message {
  color: red;
  font-size: 1rem;
}

@media (max-width: 62rem) {
  .rhhf-group {
    flex-direction: column;
  }
  .rhhf-error {
    margin: 0.3125rem 0 0;
    position: initial;
    bottom: initial;
  }
}

.rhhf-skeleton {
  animation: rhhf-skeleton-loading 1s linear infinite alternate;
  height: 6.25rem;
  border-radius: 0.25rem;
  margin-bottom: 1.25rem;
}

@keyframes rhhf-skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
